@import url(https://fonts.googleapis.com/css?family=Lato);
.Flex_flex-row__b6Ohk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Flex_flex-column__Tv_sm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Flex_flex-wrap__-WLHk {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Flex_flexRow__3pyGH {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Flex_flexColumn__itupy {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Flex_flexGrid__1iE15 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.Flex_middle__2US8c {
  -webkit-align-items: center;
          align-items: center;
}

.Flex_strech__3nDuw {
  -webkit-align-items: stretch;
          align-items: stretch;
}

.Flex_center__1F9XW {
  -webkit-justify-content: center;
          justify-content: center;
}

.Flex_space-between__31Ep2 {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Flex_space-around__1hy3c {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.Flex_end__2u7ry {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.Flex_right__39ZTB {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

/* Maybe should we use SASS? */
.Flex_flex-0__3DCHm {
  -webkit-flex: 0 1;
          flex: 0 1;
}

.Flex_flex-1__yLbID {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Flex_flex-2__32kAl {
  -webkit-flex: 2 1;
          flex: 2 1;
}

.Flex_flex-3__1DG8v {
  -webkit-flex: 3 1;
          flex: 3 1;
}

.Flex_flex-4__2KNig {
  -webkit-flex: 4 1;
          flex: 4 1;
}

.Flex_flex-5__3Q0hm {
  -webkit-flex: 5 1;
          flex: 5 1;
}

.Flex_flex-6__3npy6 {
  -webkit-flex: 6 1;
          flex: 6 1;
}

.Flex_flex-grow-0__26_LA {
  -webkit-flex-grow: 0;
          flex-grow: 0;
}

.Flex_flex-grow-1__2n2Qj {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.Flex_flex-grow-2__1YsD- {
  -webkit-flex-grow: 2;
          flex-grow: 2;
}

.Flex_flex-grow-3__32NU5 {
  -webkit-flex-grow: 3;
          flex-grow: 3;
}

.Flex_flex-grow-4__h185I {
  -webkit-flex-grow: 4;
          flex-grow: 4;
}

.Flex_flex-grow-5__358gr {
  -webkit-flex-grow: 5;
          flex-grow: 5;
}

.Layout_layout__24kj1 {
  color: #fff;
  height: 1080px;
  background: #242424;
}

.Layout_background__6m8mu {
  /*background: url('../../assets/img/background.jpg') no-repeat 100%;*/
  width: 100%;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 1280px) {
  .Layout_layout__24kj1 {
    height: 100vh;
  }
}

/* http://david.ingledow.co.uk/blog/google-material-designs-animated-loading-spinner-svg-and-css/ */
.Spinner_spinner__absolute__1DM-q {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.Spinner_loader__circular__3vnNb {
  -webkit-animation: Spinner_rotate__39Rt5 2s linear infinite;
          animation: Spinner_rotate__39Rt5 2s linear infinite;
  height: 100px;
  position: relative;
  width: 100px; }

.Spinner_loader__small__2RLsf {
  height: 100px / 2.5; }

.Spinner_loader__path__s7n1z {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: Spinner_dash__3jtIR 1.5s ease-in-out infinite, Spinner_color__2ygeN 6s ease-in-out infinite;
          animation: Spinner_dash__3jtIR 1.5s ease-in-out infinite, Spinner_color__2ygeN 6s ease-in-out infinite;
  stroke-linecap: round; }

.Spinner_loader__singleColor__1WIow {
  -webkit-animation: Spinner_dash__3jtIR 1.5s ease-in-out infinite;
          animation: Spinner_dash__3jtIR 1.5s ease-in-out infinite;
  stroke: #eee; }

@-webkit-keyframes Spinner_rotate__39Rt5 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes Spinner_rotate__39Rt5 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes Spinner_dash__3jtIR {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124; } }

@keyframes Spinner_dash__3jtIR {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124; } }

@-webkit-keyframes Spinner_color__2ygeN {
  100%,
  0% {
    stroke: #d62d20; }
  40% {
    stroke: #0057e7; }
  66% {
    stroke: #008744; }
  80%,
  90% {
    stroke: #ffa700; } }

@keyframes Spinner_color__2ygeN {
  100%,
  0% {
    stroke: #d62d20; }
  40% {
    stroke: #0057e7; }
  66% {
    stroke: #008744; }
  80%,
  90% {
    stroke: #ffa700; } }

.IconButton_icon__3MHKR {
  -webkit-align-items: center;
          align-items: center;
  -webkit-animation: IconButton_fadeSlideIn__2udRZ 500ms ease-out;
  animation: IconButton_fadeSlideIn__2udRZ 500ms ease-out;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.ChooseStudioSelector_chooseStudioSelector__2mMwd {
  height: 540px;
}

.ChooseStudioSelector_chooseStudioSectionWrapper__3bG94 {
  width: 100%;
  height: 100%;
}

.ChooseStudioSelector_chooseStudioSection__34q65 {
  width: 100%;
  padding: 31px;
  height: 50%;
  box-sizing: border-box;
}

.ChooseStudioSelector_item__2HFw- {
  font-family: Gotham;
  font-size: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin: 0px 31px;
  padding-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  border-bottom: 3px solid #fff;
  -webkit-animation: ChooseStudioSelector_fadeSlideInLeft__Ub54R 750ms ease-out;
          animation: ChooseStudioSelector_fadeSlideInLeft__Ub54R 750ms ease-out;
}

.ChooseStudioSelector_item__2HFw-:first-of-type {
  margin: 0px 31px 0px 0px;
}

.ChooseStudioSelector_item__2HFw-:last-of-type {
  margin: 0px 0px 0px 31px;
}

.ChooseStudioSelector_footer__10-Wa {
  height: 242.5px;
}

.ChooseStudioSelector_iconTitle__2dsJv {
  font-family: Gotham;
  font-weight: 500;
  font-size: 30px;
  font-weight: 500;
  margin-top: 30px;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .ChooseStudioSelector_chooseStudioSelector__2mMwd {
    -webkit-flex: 1 0 50%;
            flex: 1 0 50%;
  }

  .ChooseStudioSelector_item__2HFw- {
    font-size: 24px;
  }

  .ChooseStudioSelector_iconTitle__2dsJv {
    font-size: 18px;
  }

  .ChooseStudioSelector_footer__10-Wa {
    height: 50%;
  }
}

@-webkit-keyframes ChooseStudioSelector_fadeSlideInLeft__Ub54R {
  from {
    opacity: 0;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes ChooseStudioSelector_fadeSlideInLeft__Ub54R {
  from {
    opacity: 0;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes ChooseStudioSelector_fadeSlideIn__1n_jL {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes ChooseStudioSelector_fadeSlideIn__1n_jL {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.ChooseStudioHeader_chooseHeader__3_g5w {
  height: 450px;
  font-family: Raleway;
  font-weight: 800;
  margin-top: 180px;
  text-transform: uppercase;
  text-align: center;
}

.ChooseStudioHeader_chooseHeaderTitle__2eA8w {
  padding: 0;
  margin: 0;
  font-size: 100px;
  -webkit-animation: ChooseStudioHeader_fadeIn__3yjvf 500ms ease-out;
          animation: ChooseStudioHeader_fadeIn__3yjvf 500ms ease-out;
}


@media only screen and (max-width: 1280px) {
  .ChooseStudioHeader_chooseHeader__3_g5w {
    -webkit-flex: 1 1 30%;
            flex: 1 1 30%;
    margin-top: 200px;
  }

  .ChooseStudioHeader_chooseHeaderTitle__2eA8w {
    font-size: 64px;
  }
}

@-webkit-keyframes ChooseStudioHeader_fadeIn__3yjvf {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ChooseStudioHeader_fadeIn__3yjvf {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.CheckinHeader_checkinHeader__2qjj- {
  background: #000;
  box-sizing: border-box;
  color: #fff;
  height: 242px;
  padding: 24px 90px;
  position: relative;
  width: 100%;
}

.CheckinHeader_checkinHeader__left__1M8Mm {
  -webkit-align-items: center;
          align-items: center;
  -webkit-animation: CheckinHeader_fadeInSideLeft__3bPlD 500ms ease-out;
          animation: CheckinHeader_fadeInSideLeft__3bPlD 500ms ease-out;
}

.CheckinHeader_checkinHeader__left__info__GKMLB {
  color: #ffffff;
  font-family: Raleway;
  font-size: 80px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: normal;
  margin-left: 67px;
  text-transform: uppercase;
}

.CheckinHeader_checkinHeader__right__viBCO {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-animation: CheckinHeader_fadeInSideRight__27F5A 250ms ease-out;
          animation: CheckinHeader_fadeInSideRight__27F5A 250ms ease-out;
  color: #ffffff;
  font-family: Gotham;
  font-size: 50px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.7px;
  line-height: normal;
  text-align: right;
  text-transform: uppercase;
}

.CheckinHeader_checkinHeader__studio__c_4PC {
  text-align: center;
  text-transform: uppercase;
}

.CheckinHeader_checkinHeader__studio__name__1H8Tx {
  font-weight: 800;
}

@media only screen and (max-width: 1280px) {
  .CheckinHeader_checkinHeader__2qjj- {
    height: 188px;
    padding: 12px 45px;
  }

  .CheckinHeader_checkinHeader__left__info__GKMLB {
    font-size: 50px;
    margin-left: 36px;
  }
}

@-webkit-keyframes CheckinHeader_fadeInSideLeft__3bPlD {
  from {
    opacity: 0;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes CheckinHeader_fadeInSideLeft__3bPlD {
  from {
    opacity: 0;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes CheckinHeader_fadeInSideRight__27F5A {
  from {
    opacity: 0;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes CheckinHeader_fadeInSideRight__27F5A {
  from {
    opacity: 0;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.ParticipantItem_wrapper__109kK {
  height: inherit;
  -webkit-flex: none;
          flex: none;
  width: 192px;
}

.ParticipantItem_participantItem__KH-av {
  cursor: pointer;
  float: left;
  width: 192px;
  max-width: 192px;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}

.ParticipantItem_participantItem__KH-av:after {
  background-image: rgba(0, 0, 0, 0);
  bottom: 0px;
  content: "";
  height: 5px;
  left: 0px;
  position: absolute;
  transition: background 500ms;
  width: 192px;
}

.ParticipantItem_participantItem__row1__1ASyD {
  height: 838px;
}

.ParticipantItem_participantItem__row2__1DegN {
  height: 419px;
}

.ParticipantItem_participantItem__row3__a9E7F {
  height: 279.333333333px;
}

.ParticipantItem_participantItem__row4__3Uuxr {
  height: 209.5px;
}

.ParticipantItem_participantItem__img__eoeFQ {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  height: inherit;
  overflow: hidden;
  min-width: 192px;
  -o-object-fit: cover;
     object-fit: cover;
}

.ParticipantItem_participantItem__picture__3SFTK {
  -webkit-align-items: center;
          align-items: center;
  background: none;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: inherit;
  -webkit-justify-content: center;
          justify-content: center;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 192px;
  min-width: 192px;
}

.ParticipantItem_participantItem__position__2FijJ {
  bottom: 0px;
  color: #ffffff;
  font-family: Raleway;
  font-size: 48px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.7px;
  line-height: normal;
  position: absolute;
  right: 5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.ParticipantItem_participantItem__name__3GuFA {
  bottom: 5px;
  color: #ffffff;
  font-family: Raleway;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  height: 24px;
  left: 9px;
  letter-spacing: 0.2px;
  line-height: normal;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  width: 120px;
  z-index: 2;
}

.ParticipantItem_participantItem__badge__2RXL9 {
  background: #000;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 25px;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  width: 25px;
}

.ParticipantItem_participantItem__overlay__1cgbW {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-animation: ParticipantItem_ripple__1JjeH 250ms ease-out;
  animation: ParticipantItem_ripple__1JjeH 250ms ease-out;
  background-color: rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: inherit;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 192px;
  z-index: 2;
}

.ParticipantItem_participantItem__overlay__inner__3bzd1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  box-sizing: border-box;
  height: inherit;
  padding: 20px 14px 10px 14px;
  width: 192px;
  box-sizing: border-box;
}

.ParticipantItem_participantItem__overlay__secitonButton__2CCP4 {
  width: 164px;
}

.ParticipantItem_participantItem__overlay__sectionName__2_DeW {
  margin-bottom: 18px;
}

.ParticipantItem_participantItem__overlay__name__2KVjI {
  color: #ffffff;
  font-family: Raleway;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.2px;
  line-height: normal;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
}

.ParticipantItem_participantItem__overlay__iconImg__1EvvG {
  -webkit-animation: ParticipantItem_fadeInSideTop__3W4DK 700ms ease-out;
  animation: ParticipantItem_fadeInSideTop__3W4DK 700ms ease-out;
  height: 32px;
  width: 32px;
}

.ParticipantItem_participantItem__overlay__button__61Jfn {
  -webkit-animation: ParticipantItem_fadeInSideTop__3W4DK 700ms ease-out;
  animation: ParticipantItem_fadeInSideTop__3W4DK 700ms ease-out;
  height: 122px;
  width: 122px;
}

.ParticipantItem_participantItem__iconBirthday__1JCYO {
  position: absolute;
  top: 5px;
  right: 5px;
}

.ParticipantItem_participantItem__statusBars__sm5Lk {
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.ParticipantItem_participantItem__barPresence__8j9VQ {
  -webkit-animation: ParticipantItem_extend__1TdML 250ms ease-out;
  animation: ParticipantItem_extend__1TdML 250ms ease-out;
  background-image: linear-gradient(92deg, #00e464, #01b499);
  height: 13px;
  width: 100%;
  z-index: 1;
}

.ParticipantItem_participantItem__barFirstTime__zPPVc {
  -webkit-animation: ParticipantItem_extend__1TdML 250ms ease-out;
  animation: ParticipantItem_extend__1TdML 250ms ease-out;
  background-image: linear-gradient(93deg, #005ce4, #01b499);
  height: 13px;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 1280px) {
  .ParticipantItem_participantItem__KH-av {
    -webkit-flex-basis: 16.666666666%;
            flex-basis: 16.666666666%;
    max-width: 16.666666666%;
    min-height: 33.3333333333%;
  }

  .ParticipantItem_wrapper__109kK {
    -webkit-flex: 1 1;
            flex: 1 1;
    min-height: 100%;
    width: 100%;
  }

  .ParticipantItem_participantItem__picture__3SFTK {
    -webkit-flex-basis: 16.666666666%;
            flex-basis: 16.666666666%;
    width: 100%;
    min-height: 100%;
  }

  .ParticipantItem_participantItem__img__eoeFQ {
    height: 100%;
    width: 100%;
  }

  .ParticipantItem_participantItem__row1__1ASyD,
  .ParticipantItem_participantItem__row2__1DegN,
  .ParticipantItem_participantItem__row3__a9E7F,
  .ParticipantItem_participantItem__row4__3Uuxr {
    min-height: 33.3333333333%;
  }

  .ParticipantItem_participantItem__KH-av.ParticipantItem_checked__33kgl:after {
    height: 8px;
  }

  .ParticipantItem_participantItem__name__3GuFA {
    width: calc(9vw + 5px);
    bottom: 5px;
    left: 9px;
  }

  .ParticipantItem_participantItem__overlay__1cgbW {
    height: 100%;
    width: 100%;
  }

  .ParticipantItem_participantItem__overlay__inner__3bzd1 {
    width: 100%;
    height: 100%;
  }

  .ParticipantItem_participantItem__overlay__secitonButton__2CCP4 {
    width: 100%;
  }

  .ParticipantItem_participantItem__overlay__sectionName__2_DeW {
    margin-bottom: 12px;
  }

  .ParticipantItem_participantItem__overlay__name__2KVjI {
    font-size: 18px;
  }

  .ParticipantItem_participantItem__overlay__iconImg__1EvvG {
    height: 41px;
    width: 41px;
  }

  .ParticipantItem_participantItem__position__2FijJ {
    font-size: calc(4vw - 4px);
  }
}

@-webkit-keyframes ParticipantItem_extend__1TdML {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  20% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes ParticipantItem_extend__1TdML {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  20% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes ParticipantItem_ripple__1JjeH {
  0% {
    /* transform: scale(0, 0); */
    opacity: 0;
  }
  20% {
    opacity: 0.1;
  }
  100% {
    /* transform: scale(1, 1); */
    opacity: 1;
  }
}

@keyframes ParticipantItem_ripple__1JjeH {
  0% {
    /* transform: scale(0, 0); */
    opacity: 0;
  }
  20% {
    opacity: 0.1;
  }
  100% {
    /* transform: scale(1, 1); */
    opacity: 1;
  }
}

@-webkit-keyframes ParticipantItem_fadeInSideTop__3W4DK {
  0% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes ParticipantItem_fadeInSideTop__3W4DK {
  0% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes ParticipantItem_fadeSlideInRight__LDHYJ {
  from {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes ParticipantItem_fadeSlideInRight__LDHYJ {
  from {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

.ParticipantList_participantList__1wAg5 {
  overflow: auto;
  height: 838px;
}

.ParticipantList_participantListSection__2cffA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.ParticipantList_participantListTitle__1gVK2 {
  font-family: Raleway;
  font-weight: 900;
  color: #737373;
  font-size: 130px;
  height: 130px;
  padding: 0;
  margin: 0;
}


@media only screen and (max-width: 1280px) {

  .ParticipantList_participantListTitle__1gVK2 {
    font-family: Raleway;
    font-weight: 900;
    color: #737373;
    font-size: calc(4vw + 15px);
    height: calc(4vw + 15px);
    padding: 0;
    margin: 0;
  }
}

.ItemHeader_root__2MkhD {
  display: -webkit-flex;
  display: flex;
  color: #fff;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  min-width: 17vw;
  margin: 0 1vw;
  font-size: 2vw;
  font-weight: 600;
}

@-webkit-keyframes ItemHeader_fadein__27CQ3 {
  from {
    /* opacity: 0; */
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }

  to {
    /* opacity: 1; */
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes ItemHeader_fadein__27CQ3 {
  from {
    /* opacity: 0; */
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }

  to {
    /* opacity: 1; */
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.ItemHeader_number__2Kyjv {
  -webkit-font-feature-settings: "zero";
          font-feature-settings: "zero";
  font-variant-numeric: slashed-zero;
}

.ItemHeader_textCoachName__3Ltpn {
  text-transform: uppercase;
}

.ItemHeader_selected__1WHaF .ItemHeader_number__2Kyjv {
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  display: inline;
}

.ItemHeader_selected__1WHaF .ItemHeader_text__35hlL {
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  display: inline;
}

.ItemHeader_selected__1WHaF {
  color: transparent;
}

.ItemHeader_third__21IhB {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 2vw;
}

.ItemHeader_iconFire__2RGtS {
  position: absolute;
  top: 5px;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
}

.Header_root__fwHf1 {
  height: 110px;
  width: 100%;
  background: #191919;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 2vw;
}

.Header_iconWrapper__1-Yls {
  padding-right: 2vw;
  /* min-width: 20vw; */
}

.Header_list__6H1nh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  overflow-x: scroll;
  -webkit-align-items: center;
          align-items: center;
  width: calc(100% - 2vw);
  height: 100%;
}

.Header_list__6H1nh::-webkit-scrollbar {
  width: 0 !important;
}

.ItemSidebar_root__u6bt1 {
  position: relative;
  color: #fff;
  font-size: 2.2vw;
  padding: 1vh 0;
  margin: 3vh 0;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
}

@-webkit-keyframes ItemSidebar_fadein__14ocV {
  from {
    /* opacity: 0; */
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }

  to {
    /* opacity: 1; */
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes ItemSidebar_fadein__14ocV {
  from {
    /* opacity: 0; */
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }

  to {
    /* opacity: 1; */
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.ItemSidebar_number__3WtHs {
  -webkit-font-feature-settings: "zero";
          font-feature-settings: "zero";
  font-variant-numeric: slashed-zero;
}

.ItemSidebar_name__36jvm {
  display: inline-block;
  margin-right: 5px;
}

.ItemSidebar_inner__Jyg8X {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-bottom: 15px;
  background: linear-gradient(to right, #fff, #fff) left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 100% 3px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.ItemSidebar_selected__1WbFx .ItemSidebar_name__36jvm,
.ItemSidebar_selected__1WbFx .ItemSidebar_time__y5aGa,
.ItemSidebar_selected__1WbFx .ItemSidebar_hub__1rxJd {
  color: transparent;
  text-decoration: underline;
  text-underline-position: under;
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
}

.ItemSidebar_selected__1WbFx .ItemSidebar_inner__Jyg8X {
  padding-bottom: 15px;
  background: linear-gradient(to right, #ff7e1f, #d40032) left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 100% 3px;
}

.ItemSidebar_selected__1WbFx .ItemSidebar_number__3WtHs {
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  display: inline;
}

.ItemSidebar_selected__1WbFx .ItemSidebar_text__inUjm {
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  display: inline;
}

.ItemSidebar_selected__1WbFx {
  color: transparent;
}

.ItemSidebar_iconFire__3lNae {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
}

.Sidebar_root__1H3o_ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 100;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 1vh 5vw;
  -webkit-animation: Sidebar_fadein__56zF2 200ms ease-in-out;
          animation: Sidebar_fadein__56zF2 200ms ease-in-out;
}

@-webkit-keyframes Sidebar_fadein__56zF2 {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes Sidebar_fadein__56zF2 {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.Sidebar_list__363v3 {
  overflow-y: scroll;
}

.Sidebar_column__2durE {
  margin: 0 1vw;
  min-width: 18vw;
}

.Sidebar_itemHub__3XUIU {
  position: relative;
  color: #fff;
  font-size: 2.2vw;
  padding: 0;
  margin: 3vh 0;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
}

.Sidebar_itemHub__3XUIU .Sidebar_itemListInner__14FF4 {
  display: inline;
}

.Sidebar_itemListName__2UsGr {
  display: inline-block;
  margin-right: 5px;
}

.Sidebar_itemListInner__14FF4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-bottom: 15px;
  background: linear-gradient(to right, #fff, #fff) left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 100% 0px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Sidebar_itemListActive__39BUe .Sidebar_itemListHub__LdDLf {
  color: transparent;
  text-decoration: underline;
  text-underline-position: under;
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
}

.Sidebar_itemListActive__39BUe .Sidebar_itemListInner__14FF4 {
  padding-bottom: 15px;
  background: linear-gradient(to right, #ff7e1f, #d40032) left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 100% 0px;
}

.Sidebar_loading__1vrT5 {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.ItemParticipant_root__2cErc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: calc(100vw / 9);
          flex-basis: calc(100vw / 9);
  min-width: calc(100vw / 9);
  height: 102px;
  background: black;
  position: relative;
  -webkit-animation: ItemParticipant_fadein__3fb7- 500ms ease-out;
          animation: ItemParticipant_fadein__3fb7- 500ms ease-out;
}

@-webkit-keyframes ItemParticipant_fadein__3fb7- {
  from {
    opacity: 0;
    /* transform: translateX(100px); */
  }

  to {
    opacity: 1;
    /* transform: translateX(0px); */
  }
}

@keyframes ItemParticipant_fadein__3fb7- {
  from {
    opacity: 0;
    /* transform: translateX(100px); */
  }

  to {
    opacity: 1;
    /* transform: translateX(0px); */
  }
}

.ItemParticipant_root__2cErc:after {
  background-image: rgba(0, 0, 0, 0);
  bottom: 0px;
  content: "";
  height: 13px;
  left: 0px;
  position: absolute;
  transition: background 500ms;
  width: 192px;
}

.ItemParticipant_root__2cErc:before {
  background-image: rgba(0, 0, 0, 0);
  bottom: 13px;
  content: "";
  height: 13px;
  left: 0px;
  position: absolute;
  transition: background 500ms;
  width: 192px;
}

.ItemParticipant_img__3wgGW {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.ItemParticipant_position__3lYJc {
  bottom: 2px;
  color: #ffffff;
  font-size: 1.3vw;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.7px;
  line-height: normal;
  position: absolute;
  right: 5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.ItemParticipant_name__SNY2T {
  white-space: pre;
  bottom: 2px;
  color: #ffffff;
  font-size: 1.3vw;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  left: 9px;
  letter-spacing: 0.2px;
  line-height: normal;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  width: 65%;
  z-index: 2;
}

.ItemParticipant_bars__1pwqZ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 20px;
  width: 100%;
}

.ItemParticipant_barFirst__1Gvb8 {
  -webkit-animation: ItemParticipant_extend__2z60k 250ms ease-out;
  animation: ItemParticipant_extend__2z60k 250ms ease-out;
  background-image: linear-gradient(93deg, #005ce4, #01b499);
  height: 7px;
  width: 100%;
  z-index: 1;
}

.ItemParticipant_barPresent__6UfW- {
  -webkit-animation: ItemParticipant_extend__2z60k 250ms ease-out;
  animation: ItemParticipant_extend__2z60k 250ms ease-out;
  background-image: linear-gradient(92deg, #00e464, #01b499);
  height: 7px;
  width: 100%;
  z-index: 1;
}

.ItemParticipant_icon__25dI3 {
  position: absolute;
  z-index: 2;
}

.ItemParticipant_icon__25dI3:nth-of-type(2) {
  left: 8px;
}

.ItemParticipant_icon__25dI3:nth-of-type(3) {
  left: 16px;
}

.ItemParticipant_iconSession__38h58 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 21px;
  height: 21px;
  background: black;
  color: #fff;
  border-radius: 21px;
  margin: 1px;
}

.ItemParticipant_iconSession__38h58:before {
  display: inline-block;
  color: transparent;
  text-decoration: underline;
  text-underline-position: under;
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  content: 'S';
  font-size: 14px;
  margin-top: 2px;
}

.ItemParticipant_iconBirthday__19gcg {
  position: absolute;
  right: 5px;
}

.ModalParticipant_modal__UP1et {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  z-index: 10;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: calc(100vh - 110px);
  top: 0;
  left: 0;
  color: #fff;
  -webkit-animation: ModalParticipant_fadein__2md9T 200ms ease-in-out;
          animation: ModalParticipant_fadein__2md9T 200ms ease-in-out;
}

@-webkit-keyframes ModalParticipant_fadein__2md9T {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ModalParticipant_fadein__2md9T {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ModalParticipant_img__mPtZy {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.ModalParticipant_wrapperImg__8eqHf {
  width: 100%;
  height: 100%;
}

.ModalParticipant_panel__2WYvf {
  background: #000;
  height: 45vh;
  min-height: 230px;
  width: 400px;
}

.ModalParticipant_panelInner__2Y7vc {
  height: 100%;
}

.ModalParticipant_userInfoList__VNond {
  list-style: none;
  padding: 1.5vh 0.3vw;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.ModalParticipant_userInfoList__VNond li {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  width: 90%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 1.2vh;
  font-size: 14px;
  font-weight: bold;
}

.ModalParticipant_userInfoList__VNond .ModalParticipant_subscription__Pn4TP {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.ModalParticipant_icon__2dJHQ {
  box-shadow: 1px 2px 2px 1px rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  width: 28px;
  height: 28px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.ModalParticipant_firstRow__QsCiY {
}

.ModalParticipant_icon__2dJHQ {
  margin-left: 10px;
}

.ModalParticipant_icon__2dJHQ:first-child {
  margin-left: 0px;
}

.ModalParticipant_iconSession__3kBJY {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 28px;
  height: 28px;
  background: black;
  color: #fff;
  border-radius: 28px;
}

.ModalParticipant_iconSession__3kBJY:before {
  display: inline-block;
  color: transparent;
  text-decoration: underline;
  text-underline-position: under;
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  content: 'S';
  font-size: 14px;
  margin-top: 4px;
}

.ListParticipant_root__dnfsk {
  position: relative;
  height: calc(100% - 110px);
}

.ListParticipant_loading__e_SFV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: calc(100vh - 110px);
}

.ListParticipant_title__2htIM {
  color: #fff;
  font-weight: 500;
  margin: 0 3vw;
  padding: 2vh 0;
  font-size: 24px;
}

.ListParticipant_layoutEmpty__18bn6 {
  height: calc(100vh - 110px);
}

.ListParticipant_modalAlert__2EYJe {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  top: 50px;
  left: calc(50% - 150px);
  width: 300px;
  height: 200px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 10px;
  z-index: 50;
  -webkit-animation: ListParticipant_fadeIn__2m2TK 250ms ease-in-out;
          animation: ListParticipant_fadeIn__2m2TK 250ms ease-in-out;
  font-weight: 600;
}

@-webkit-keyframes ListParticipant_fadeIn__2m2TK {
  from {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes ListParticipant_fadeIn__2m2TK {
  from {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.ListParticipant_sectionSort__gAh9L {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  top: 10px;
  right: 20px;
}

.ListParticipant_sectionSortButton__3T8c3 {
  font-size: 9px;
  font-weight: 800;
  background: #fff;
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 42px;
  margin: 0 30px;
}

.TablePage_root__1-dUU,
.TablePage_rootLoading__2vmHn {
  height: var(--app-height);
  font-family: 'Gotham', sans-serif;
}

.TablePage_root__1-dUU {
  background: #454545;
}

.TablePage_rootLoading__2vmHn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: TablePage_Gradient__36OwG 10s ease infinite;
  animation: TablePage_Gradient__36OwG 10s ease infinite;
}

@-webkit-keyframes TablePage_Gradient__36OwG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes TablePage_Gradient__36OwG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.TablePage_main__1sNng {
  -webkit-animation: TablePage_fadein__1Utsx 250ms ease-in-out;
          animation: TablePage_fadein__1Utsx 250ms ease-in-out;
  height: 100%;
}

@-webkit-keyframes TablePage_fadein__1Utsx {
  from {
    opacity: 0;
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes TablePage_fadein__1Utsx {
  from {
    opacity: 0;
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  overflow: hidden;
  height: var(--app-height);
}

body {
  padding: 0;
}

.popInUp-enter {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.popInUp-enter.popInUp-enter-active {
  -webkit-transform: translateY(1);
          transform: translateY(1);
}

.popInUp-leave {
  -webkit-transform: translateY(1);
          transform: translateY(1);
}

.popInUp-leave.popInUp-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

