.participantList {
  overflow: auto;
  height: 838px;
}

.participantListSection {
  display: flex;
  flex: 1 1 50%;
  align-items: flex-end;
  justify-content: flex-end;
}

.participantListTitle {
  font-family: Raleway;
  font-weight: 900;
  color: #737373;
  font-size: 130px;
  height: 130px;
  padding: 0;
  margin: 0;
}


@media only screen and (max-width: 1280px) {

  .participantListTitle {
    font-family: Raleway;
    font-weight: 900;
    color: #737373;
    font-size: calc(4vw + 15px);
    height: calc(4vw + 15px);
    padding: 0;
    margin: 0;
  }
}
