.icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: fadeSlideIn 500ms ease-out;
  animation: fadeSlideIn 500ms ease-out;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
