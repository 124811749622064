@import url('https://fonts.googleapis.com/css?family=Lato');

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  overflow: hidden;
  height: var(--app-height);
}

body {
  padding: 0;
}

.popInUp-enter {
  transform: translateY(0);
}

.popInUp-enter.popInUp-enter-active {
  transform: translateY(1);
}

.popInUp-leave {
  transform: translateY(1);
}

.popInUp-leave.popInUp-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
