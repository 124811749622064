.layout {
  color: #fff;
  height: 1080px;
  background: #242424;
}

.background {
  /*background: url('../../assets/img/background.jpg') no-repeat 100%;*/
  width: 100%;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 1280px) {
  .layout {
    height: 100vh;
  }
}
