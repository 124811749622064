.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 100;
  display: flex;
  flex-direction: row;
  padding: 1vh 5vw;
  animation: fadein 200ms ease-in-out;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.list {
  overflow-y: scroll;
}

.column {
  margin: 0 1vw;
  min-width: 18vw;
}

.itemHub {
  position: relative;
  color: #fff;
  font-size: 2.2vw;
  padding: 0;
  margin: 3vh 0;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
}

.itemHub .itemListInner {
  display: inline;
}

.itemListName {
  display: inline-block;
  margin-right: 5px;
}

.itemListInner {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  background: linear-gradient(to right, #fff, #fff) left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 100% 0px;
  justify-content: space-between;
}

.itemListActive .itemListHub {
  color: transparent;
  text-decoration: underline;
  text-underline-position: under;
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
}

.itemListActive .itemListInner {
  padding-bottom: 15px;
  background: linear-gradient(to right, #ff7e1f, #d40032) left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 100% 0px;
}

.loading {
  align-items: center;
  justify-content: center;
}
