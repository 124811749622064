.root {
  display: flex;
  flex: 0 0 calc(100vw / 9);
  min-width: calc(100vw / 9);
  height: 102px;
  background: black;
  position: relative;
  animation: fadein 500ms ease-out;
}

@keyframes fadein {
  from {
    opacity: 0;
    /* transform: translateX(100px); */
  }

  to {
    opacity: 1;
    /* transform: translateX(0px); */
  }
}

.root:after {
  background-image: rgba(0, 0, 0, 0);
  bottom: 0px;
  content: "";
  height: 13px;
  left: 0px;
  position: absolute;
  -webkit-transition: background 500ms;
  transition: background 500ms;
  width: 192px;
}

.root:before {
  background-image: rgba(0, 0, 0, 0);
  bottom: 13px;
  content: "";
  height: 13px;
  left: 0px;
  position: absolute;
  -webkit-transition: background 500ms;
  transition: background 500ms;
  width: 192px;
}

.img {
  width: 100%;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.position {
  bottom: 2px;
  color: #ffffff;
  font-size: 1.3vw;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.7px;
  line-height: normal;
  position: absolute;
  right: 5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.name {
  white-space: pre;
  bottom: 2px;
  color: #ffffff;
  font-size: 1.3vw;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  left: 9px;
  letter-spacing: 0.2px;
  line-height: normal;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  width: 65%;
  z-index: 2;
}

.bars {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 20px;
  width: 100%;
}

.barFirst {
  -webkit-animation: extend 250ms ease-out;
  animation: extend 250ms ease-out;
  background-image: linear-gradient(93deg, #005ce4, #01b499);
  height: 7px;
  width: 100%;
  z-index: 1;
}

.barPresent {
  -webkit-animation: extend 250ms ease-out;
  animation: extend 250ms ease-out;
  background-image: linear-gradient(92deg, #00e464, #01b499);
  height: 7px;
  width: 100%;
  z-index: 1;
}

.icon {
  position: absolute;
  z-index: 2;
}

.icon:nth-of-type(2) {
  left: 8px;
}

.icon:nth-of-type(3) {
  left: 16px;
}

.iconSession {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  background: black;
  color: #fff;
  border-radius: 21px;
  margin: 1px;
}

.iconSession:before {
  display: inline-block;
  color: transparent;
  text-decoration: underline;
  text-underline-position: under;
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  content: 'S';
  font-size: 14px;
  margin-top: 2px;
}

.iconBirthday {
  position: absolute;
  right: 5px;
}
