.root {
  position: relative;
  color: #fff;
  font-size: 2.2vw;
  padding: 1vh 0;
  margin: 3vh 0;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
}

@keyframes fadein {
  from {
    /* opacity: 0; */
    transform: translateX(-10px);
  }

  to {
    /* opacity: 1; */
    transform: translateX(0px);
  }
}

.number {
  font-variant-numeric: slashed-zero;
}

.name {
  display: inline-block;
  margin-right: 5px;
}

.inner {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  background: linear-gradient(to right, #fff, #fff) left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 100% 3px;
  justify-content: space-between;
}

.selected .name,
.selected .time,
.selected .hub {
  color: transparent;
  text-decoration: underline;
  text-underline-position: under;
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
}

.selected .inner {
  padding-bottom: 15px;
  background: linear-gradient(to right, #ff7e1f, #d40032) left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 100% 3px;
}

.selected .number {
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  display: inline;
}

.selected .text {
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  display: inline;
}

.selected {
  color: transparent;
}

.iconFire {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
}
