.checkinHeader {
  background: #000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #fff;
  height: 242px;
  padding: 24px 90px;
  position: relative;
  width: 100%;
}

.checkinHeader__left {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: fadeInSideLeft 500ms ease-out;
          animation: fadeInSideLeft 500ms ease-out;
}

.checkinHeader__left__info {
  color: #ffffff;
  font-family: Raleway;
  font-size: 80px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: normal;
  margin-left: 67px;
  text-transform: uppercase;
}

.checkinHeader__right {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-animation: fadeInSideRight 250ms ease-out;
          animation: fadeInSideRight 250ms ease-out;
  color: #ffffff;
  font-family: Gotham;
  font-size: 50px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.7px;
  line-height: normal;
  text-align: right;
  text-transform: uppercase;
}

.checkinHeader__studio {
  text-align: center;
  text-transform: uppercase;
}

.checkinHeader__studio__name {
  font-weight: 800;
}

@media only screen and (max-width: 1280px) {
  .checkinHeader {
    height: 188px;
    padding: 12px 45px;
  }

  .checkinHeader__left__info {
    font-size: 50px;
    margin-left: 36px;
  }
}

@-webkit-keyframes fadeInSideLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes fadeInSideLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes fadeInSideRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes fadeInSideRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
