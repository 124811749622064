.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: calc(100vh - 110px);
  top: 0;
  left: 0;
  color: #fff;
  animation: fadein 200ms ease-in-out;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.img {
  width: 100%;
  object-fit: cover;
}

.wrapperImg {
  width: 100%;
  height: 100%;
}

.panel {
  background: #000;
  height: 45vh;
  min-height: 230px;
  width: 400px;
}

.panelInner {
  height: 100%;
}

.userInfoList {
  list-style: none;
  padding: 1.5vh 0.3vw;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.userInfoList li {
  flex: 1;
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 0 1.2vh;
  font-size: 14px;
  font-weight: bold;
}

.userInfoList .subscription {
  justify-content: flex-start;
}

.icon {
  box-shadow: 1px 2px 2px 1px rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstRow {
}

.icon {
  margin-left: 10px;
}

.icon:first-child {
  margin-left: 0px;
}

.iconSession {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: black;
  color: #fff;
  border-radius: 28px;
}

.iconSession:before {
  display: inline-block;
  color: transparent;
  text-decoration: underline;
  text-underline-position: under;
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  content: 'S';
  font-size: 14px;
  margin-top: 4px;
}
