.wrapper {
  height: inherit;
  flex: none;
  width: 192px;
}

.participantItem {
  cursor: pointer;
  float: left;
  width: 192px;
  max-width: 192px;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}

.participantItem:after {
  background-image: rgba(0, 0, 0, 0);
  bottom: 0px;
  content: "";
  height: 5px;
  left: 0px;
  position: absolute;
  -webkit-transition: background 500ms;
  transition: background 500ms;
  width: 192px;
}

.participantItem__row1 {
  height: 838px;
}

.participantItem__row2 {
  height: 419px;
}

.participantItem__row3 {
  height: 279.333333333px;
}

.participantItem__row4 {
  height: 209.5px;
}

.participantItem__img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  height: inherit;
  overflow: hidden;
  min-width: 192px;
  object-fit: cover;
}

.participantItem__picture {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: inherit;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 192px;
  min-width: 192px;
}

.participantItem__position {
  bottom: 0px;
  color: #ffffff;
  font-family: Raleway;
  font-size: 48px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.7px;
  line-height: normal;
  position: absolute;
  right: 5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.participantItem__name {
  bottom: 5px;
  color: #ffffff;
  font-family: Raleway;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  height: 24px;
  left: 9px;
  letter-spacing: 0.2px;
  line-height: normal;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  width: 120px;
  z-index: 2;
}

.participantItem__badge {
  background: #000;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 25px;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  width: 25px;
}

.participantItem__overlay {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-animation: ripple 250ms ease-out;
  animation: ripple 250ms ease-out;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: inherit;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 192px;
  z-index: 2;
}

.participantItem__overlay__inner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: inherit;
  padding: 20px 14px 10px 14px;
  width: 192px;
  box-sizing: border-box;
}

.participantItem__overlay__secitonButton {
  width: 164px;
}

.participantItem__overlay__sectionName {
  margin-bottom: 18px;
}

.participantItem__overlay__name {
  color: #ffffff;
  font-family: Raleway;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.2px;
  line-height: normal;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
}

.participantItem__overlay__iconImg {
  -webkit-animation: fadeInSideTop 700ms ease-out;
  animation: fadeInSideTop 700ms ease-out;
  height: 32px;
  width: 32px;
}

.participantItem__overlay__button {
  -webkit-animation: fadeInSideTop 700ms ease-out;
  animation: fadeInSideTop 700ms ease-out;
  height: 122px;
  width: 122px;
}

.participantItem__iconBirthday {
  position: absolute;
  top: 5px;
  right: 5px;
}

.participantItem__statusBars {
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.participantItem__barPresence {
  -webkit-animation: extend 250ms ease-out;
  animation: extend 250ms ease-out;
  background-image: linear-gradient(92deg, #00e464, #01b499);
  height: 13px;
  width: 100%;
  z-index: 1;
}

.participantItem__barFirstTime {
  -webkit-animation: extend 250ms ease-out;
  animation: extend 250ms ease-out;
  background-image: linear-gradient(93deg, #005ce4, #01b499);
  height: 13px;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 1280px) {
  .participantItem {
    -ms-flex-preferred-size: 16.666666666%;
    flex-basis: 16.666666666%;
    max-width: 16.666666666%;
    min-height: 33.3333333333%;
  }

  .wrapper {
    flex: 1 1 0%;
    min-height: 100%;
    width: 100%;
  }

  .participantItem__picture {
    -ms-flex-preferred-size: 16.666666666%;
    flex-basis: 16.666666666%;
    width: 100%;
    min-height: 100%;
  }

  .participantItem__img {
    height: 100%;
    width: 100%;
  }

  .participantItem__row1,
  .participantItem__row2,
  .participantItem__row3,
  .participantItem__row4 {
    min-height: 33.3333333333%;
  }

  .participantItem.checked:after {
    height: 8px;
  }

  .participantItem__name {
    width: calc(9vw + 5px);
    bottom: 5px;
    left: 9px;
  }

  .participantItem__overlay {
    height: 100%;
    width: 100%;
  }

  .participantItem__overlay__inner {
    width: 100%;
    height: 100%;
  }

  .participantItem__overlay__secitonButton {
    width: 100%;
  }

  .participantItem__overlay__sectionName {
    margin-bottom: 12px;
  }

  .participantItem__overlay__name {
    font-size: 18px;
  }

  .participantItem__overlay__iconImg {
    height: 41px;
    width: 41px;
  }

  .participantItem__position {
    font-size: calc(4vw - 4px);
  }
}

@-webkit-keyframes extend {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  20% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes extend {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  20% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes ripple {
  0% {
    /* transform: scale(0, 0); */
    opacity: 0;
  }
  20% {
    opacity: 0.1;
  }
  100% {
    /* transform: scale(1, 1); */
    opacity: 1;
  }
}

@keyframes ripple {
  0% {
    /* transform: scale(0, 0); */
    opacity: 0;
  }
  20% {
    opacity: 0.1;
  }
  100% {
    /* transform: scale(1, 1); */
    opacity: 1;
  }
}

@-webkit-keyframes fadeInSideTop {
  0% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes fadeInSideTop {
  0% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes fadeSlideInRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes fadeSlideInRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
