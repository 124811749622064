/* http://david.ingledow.co.uk/blog/google-material-designs-animated-loading-spinner-svg-and-css/ */
.spinner__absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.loader__circular {
  animation: rotate 2s linear infinite;
  height: 100px;
  position: relative;
  width: 100px; }

.loader__small {
  height: 100px / 2.5; }

.loader__path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round; }

.loader__singleColor {
  animation: dash 1.5s ease-in-out infinite;
  stroke: #eee; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124; } }

@keyframes color {
  100%,
  0% {
    stroke: #d62d20; }
  40% {
    stroke: #0057e7; }
  66% {
    stroke: #008744; }
  80%,
  90% {
    stroke: #ffa700; } }
