.chooseStudioSelector {
  height: 540px;
}

.chooseStudioSectionWrapper {
  width: 100%;
  height: 100%;
}

.chooseStudioSection {
  width: 100%;
  padding: 31px;
  height: 50%;
  box-sizing: border-box;
}

.item {
  font-family: Gotham;
  font-size: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin: 0px 31px;
  padding-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  border-bottom: 3px solid #fff;
  animation: fadeSlideInLeft 750ms ease-out;
}

.item:first-of-type {
  margin: 0px 31px 0px 0px;
}

.item:last-of-type {
  margin: 0px 0px 0px 31px;
}

.footer {
  height: 242.5px;
}

.iconTitle {
  font-family: Gotham;
  font-weight: 500;
  font-size: 30px;
  font-weight: 500;
  margin-top: 30px;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .chooseStudioSelector {
    flex: 1 0 50%;
  }

  .item {
    font-size: 24px;
  }

  .iconTitle {
    font-size: 18px;
  }

  .footer {
    height: 50%;
  }
}

@keyframes fadeSlideInLeft {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
