.root {
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 17vw;
  margin: 0 1vw;
  font-size: 2vw;
  font-weight: 600;
}

@keyframes fadein {
  from {
    /* opacity: 0; */
    transform: translateX(-10px);
  }

  to {
    /* opacity: 1; */
    transform: translateX(0px);
  }
}

.number {
  font-variant-numeric: slashed-zero;
}

.textCoachName {
  text-transform: uppercase;
}

.selected .number {
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  display: inline;
}

.selected .text {
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  -webkit-background-clip: text;
  display: inline;
}

.selected {
  color: transparent;
}

.third {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 2vw;
}

.iconFire {
  position: absolute;
  top: 5px;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  -webkit-mask-image: linear-gradient(to right, #ff7e1f, #d40032);
  background: linear-gradient(to right, #ff7e1f, #d40032);
  background: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
  background-image: linear-gradient(to right, #ff7e1f, #d40032);
  background-image: -webkit-linear-gradient(to right, #ff7e1f, #d40032);
}
