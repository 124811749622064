.root {
  height: 110px;
  width: 100%;
  background: #191919;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2vw;
}

.iconWrapper {
  padding-right: 2vw;
  /* min-width: 20vw; */
}

.list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: scroll;
  align-items: center;
  width: calc(100% - 2vw);
  height: 100%;
}

.list::-webkit-scrollbar {
  width: 0 !important;
}
