.root {
  position: relative;
  height: calc(100% - 110px);
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 110px);
}

.title {
  color: #fff;
  font-weight: 500;
  margin: 0 3vw;
  padding: 2vh 0;
  font-size: 24px;
}

.layoutEmpty {
  height: calc(100vh - 110px);
}

.modalAlert {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50px;
  left: calc(50% - 150px);
  width: 300px;
  height: 200px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 10px;
  z-index: 50;
  animation: fadeIn 250ms ease-in-out;
  font-weight: 600;
}

@keyframes fadeIn {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.sectionSort {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 20px;
}

.sectionSortButton {
  font-size: 9px;
  font-weight: 800;
  background: #fff;
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 42px;
  margin: 0 30px;
}
