.chooseHeader {
  height: 450px;
  font-family: Raleway;
  font-weight: 800;
  margin-top: 180px;
  text-transform: uppercase;
  text-align: center;
}

.chooseHeaderTitle {
  padding: 0;
  margin: 0;
  font-size: 100px;
  animation: fadeIn 500ms ease-out;
}


@media only screen and (max-width: 1280px) {
  .chooseHeader {
    flex: 1 1 30%;
    margin-top: 200px;
  }

  .chooseHeaderTitle {
    font-size: 64px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
